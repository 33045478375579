<script>
/**
 * Private Navbar component
 */
import {AUTH_SIGNOUT} from '@/store/actions/auth'
export default {
  data() {
    return {
      isCondensed: false,
      navLight_in_home: false
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
    navOptions: {
      type: Boolean,
      default: true
    },
    hideMedicalRecordOpt: {
      type: Boolean,
      default: false
    },
    hidePaymentOpt: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    signout () {
      this.$store.dispatch(AUTH_SIGNOUT)
        .then(() => {
          this.$router.push({ name: 'signout'})
        })
        .catch(error => {
          console.error(error)
        })
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },

    onScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");

        if (this.navLight) {
          document.getElementById("signupBtn").classList.add("btn-primary");
          document.getElementById("signupBtn").classList.remove("btn-light");
        }
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
        
        if (this.navLight) {
          document.getElementById("signupBtn").classList.remove("btn-primary");
          document.getElementById("signupBtn").classList.add("btn-light");
        }
      }

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }
  },

  mounted() {
    window.onscroll = () => {
      //this.onScroll()
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  }
  
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }">
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/private/dashboard" v-if="navLight !== true">
            <img src="/images/duppla_negro_header.png" height="24" alt="" />
          </router-link>
          <router-link class="logo" to="/private/dashboard" v-else>
            <img src="/images/duppla_negro_header.png" class="l-dark" height="24" alt="" />
            <img
              src="/images/duppla_light_header.png"
              class="l-light"
              height="24"
              alt=""
            />
          </router-link>
        </div>
        <div class="buy-button" v-if="isIcons !== true">
          <b-btn class="btn btn-danger btn-block mt-3" @click.prevent="signout">
            Cerrar Sesión
          </b-btn>
        </div>
        <ul class="buy-button list-inline mb-0" v-if="isIcons === true">
          <li class="list-inline-item mb-0 developer-icon">
            <b-dropdown
              right
              variant="link"
              toggle-class="text-decoration-none p-0 pr-2"
              menu-class="dd-menu dropdown-menu-right bg-white shadow rounded border-0 mt-3 py-0"
            >
              <template #button-content>
                <i class="mdi mdi-magnify h4 text-muted"></i>
              </template>
              <div style="width: 300px">
                <form>
                  <input
                    type="text"
                    id="text"
                    name="name"
                    class="form-control border bg-white"
                    placeholder="Search..."
                  />
                </form>
              </div>
            </b-dropdown>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"
              ><i class="mdi mdi-github mdi-18px icons"></i
            ></a>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"
              ><i class="mdi mdi-stack-overflow mdi-18px icons"></i
            ></a>
          </li>
          <li class="list-inline-item mb-0">
            <a
              href="javascript:void(0)"
              class="btn btn-icon btn-soft-primary"
              data-toggle="modal"
              data-target="#productview"
              ><i class="mdi mdi-account-outline mdi-18px icons"></i
            ></a>
          </li>
        </ul>
        <!--end login button-->
        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a class="navbar-toggle" @click="toggleMenu()" :class="{ open: isCondensed === true }">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul class="navigation-menu" :class="{ 'nav-light': navLight === true }" v-if="navOptions">
            <li>
              <router-link to="/private/dashboard" class="side-nav-link-ref">Sala de Espera</router-link>
            </li>
            <li>
              <router-link to="/private/medical-record" class="side-nav-link-ref" v-if="!hideMedicalRecordOpt">Mi Expediente</router-link>
            </li>
            <li>
              <router-link to="/private/payment" class="side-nav-link-ref" v-if="!hidePaymentOpt">Mi Pago</router-link>
            </li>
          </ul>
          <!--end navigation menu-->
          <div class="buy-menu-btn d-none">
            <b-btn class="btn btn-danger btn-block" @click.prevent="signout">
              Cerrar Sesión
            </b-btn>
          </div>
          <!--end login button-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
  </div>
</template>
